import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const Status = {
  End: "End Pending",
  Hold: "On Product Hold",
  Active: "Active Development",
  Maintenance: "Maintenance Only",
  Design: "Design",
}

const products = [
  {
    name: "Automator",
    status: Status.Hold,
    emailUser: "automator",
    jiraKeys: ["AUTO"],
    docSlug: "automator",
    stakeholders: ["Dalton"],
  },
  {
    name: "Eclipse (All)",
    status: Status.Active,
    emailUser: "eclipse",
    jiraKeys: ["EC", "MO", "EDE"],
    docSlug: "eclipse",
    stakeholders: ["John", "Randal", "Dalton", "Reed"],
  },
  {
    name: "Auto-Sampler",
    status: Status.Maintenance,
    emailUser: "auto-sampler",
    jiraKeys: [],
    docSlug: "auto-sampler",
    stakeholders: ["Dalton"],
  },
  {
    name: "Tracer",
    status: Status.Maintenance,
    emailUser: "tracer",
    jiraKeys: ["TRACR"],
    docSlug: "tracer",
    stakeholders: ["Kathryn", "Dalton"],
  },
  {
    name: "Reporting Platform",
    status: Status.Design,
    emailUser: "reporting-platform",
    jiraKeys: ["REPT"],
    docSlug: "reporting-platform",
    stakeholders: ["Peter"],
  },
  {
    name: "Skunkworks",
    status: Status.Active,
    emailUser: "skunkworks",
    jiraKeys: ["SKUNK"],
    docSlug: "skunkworks",
    stakeholders: ["John", "Dalton", "Reed"],
  },
  {
    name: "WinIStatus",
    status: Status.Active,
    emailUser: "winistatus",
    jiraKeys: ["WIS"],
    docSlug: "winistatus",
    stakeholders: ["Dalton"],
  },
].sort((a, b) => a.name.localeCompare(b.name))

const StakeholdersHome = () => (
  <Layout>
    <h1>Welcome Stakeholders!</h1>
    <p>
      This page is going to be your starting point for all things Software
      Engineering (SWE) project related.
    </p>
    <p>
      General stakeholder documents will be housed{" "}
      <a target="_blank" href="/stakeholders/shared">
        here
      </a>
      .
    </p>
    <table>
      <thead>
        <th>Product Name</th>
        <th>Status</th>
        <th>Stakeholder Email</th>
        <th>Documents Link</th>
        <th>Jira Link(s)</th>
        <th>Stakeholders</th>
      </thead>
      <tbody>
        {products.map(product => (
          <tr>
            <td>{product.name}</td>
            <td>{product.status}</td>
            <td>
              <a
                href="#"
                onClick={() =>
                  (window.location = `mailto:${product.emailUser}@wasson-ece.dev`)
                }
              >
                <span>{product.emailUser}</span>
                @wasson-ece.dev
              </a>
            </td>
            <td>
              <a target="_blank" href={`/product/${product.docSlug}`}>
                SharePoint
              </a>
            </td>
            <td>
              {product.jiraKeys.map((key, i) => (
                <>
                  <a href={`https://wasson-ece.atlassian.net/browse/${key}`}>
                    {key}
                  </a>
                  {i < product.jiraKeys.length - 1 ? ", " : ""}
                </>
              ))}
            </td>
            <td>{product.stakeholders.join(", ")}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </Layout>
)

export default StakeholdersHome
